import React, { Component } from "react";
import { Row, Container, Col } from "reactstrap";

const AutomationAndEfficiencySolutions = (props) => {
  return (
    <React.Fragment>
      <Container>
        <Row className="api-services-content">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h4>
                  <b className="color-grey">
                    Automation and Efficiency Solutions
                  </b>
                </h4>
                <br />
                <p>
                  Utilize AI Assistants to automate routine tasks and processes,
                  enhancing productivity and operational efficiency. Develop
                  tailored automation strategies that leverage the unique
                  capabilities of AI Assistants to solve complex business
                  challenges and streamline decision-making.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AutomationAndEfficiencySolutions;
